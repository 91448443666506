.custom-loader {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: conic-gradient(#0000 10%, #2d061b);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 16px), #000 0);
  animation: s3 2s infinite linear;
}
@keyframes s3 {
  to {
    transform: rotate(1turn);
  }
}

.text {
  font-family: "Passion One";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 36px;
  letter-spacing: 0.1em;
  color: #2d061b;
}
